import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Btn from "../components/button"
import List from "../components/list"
import Section from "../components/section"
import ContactFormSection from "../components/contactFormSection"
import ContentBlock from "../components/contentBlock"
import { StaticImage } from "gatsby-plugin-image"

const WebtrackingPage = ({ location }) => (
  
  <>
  <Layout 
    inverted="true"
    breadcrumb={
      {"Web-Tracking": "/webtracking"}
    }
  >
    <Seo 
      title="Web-Tracking zur Erfolgsmessung nutzen | cliqit"
      description="Dank Web-Tracking erfährst du wie sich die Besucher auf deiner Website verhalten und entdeckst neue Potenziale."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section noSeparator inverted="true" bgGradient cutOff>
      <div className="row">
        <div className="col-md-8">
          <h1 >Web-Tracking</h1>
          <p className="subheading align-left">Wie verhalten sich Nutzer auf der Website? Und wie messen Marketingplattformen, ob Nutzer das Kampagnenziel erreichten? <br />Die Lösung nennt sich Web-Tracking.</p>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <List 
            inverted="true"
            columnCount={2}
            listItems={[
              { text:"Tracking Konzeption", href:"#webtracking-konzipieren", key:"1" },
              { text:"Tracking Implementierung", href:"#tracking-integrieren", key:"2" },
              { text:"Google Tag Manager Setup", href:"#google-analytics-setup", key:"3" },
              { text:"Google Analytics Setup", href:"#google-analytics-setup", key:"4" },
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column flex-md-row justify-content-around justify-content-md-start">
          <Btn
            type="primary"
            text="Kontakt aufnehmen"
            href="#contact-form"
            inverted="true"
          />
        </div>
      </div>
    </Section>

    <Section>
      <div className="row" id="warum-webtracking">
        <div className="col">
          <div className="headings">
            <h2>Warum Web-Tracking?</h2>
            <p className="subheading">Web-Tracking bedeutet nicht gleich Datenmonster à la Amazon, Google & Co. Web-Tracking soll Webseitenbetreibern als wichtiges Instrument dienen, welches Potenziale und Stolpersteine der Besucher zu erkennen lässt.</p>
          </div>
        </div>
      </div>
      <div className="row content-row" id="webtracking-konzipieren">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/graphics/grafik-webtracking-konzept.png" 
            alt="Webtracking Konzepterstellung" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Web-Tracking konzipieren."
            text={<>Bevor man beginnt Daten zu sammeln, sollte man sich die Frage stellen,
              <strong> was man analysieren möchte</strong> und welche Daten hierfür benötigt werden.
              Ein für dich zugeschnittenes Tracking-Konzept stellt sicher, dass du 
              <strong> deine Fragen durch Nutzerdaten beantworten</strong> kannst, ohne unnötige Daten zu messen.</>}
            headingBgStart={-5}
            headingBgWidth={80}
          />
        </div>
      </div>
      <div className="row content-row" id="tracking-integrieren">
        <div className="col-md-6">
          <StaticImage 
            src="../images/graphics/grafik-webtracking-implementieren.png" 
            alt="Webtracking in Website implementieren" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Web- & Conversion-Tracking integrieren."
            text={<>Damit Werbekampagnen effizient ausgespielt werden können und der <strong>Ressourceneinsatz
            auf seine Effizienz</strong> geprüft werden kann, muss auf eine sorgfältige Integration von Web- & Conversion-Tracking geachtet werden.
            Egal ob Verkäufe im E-Commerce Shop, Lead Generierung für Neukunden oder Kontaktaufnahmen - <strong>mehr Transparenz</strong> hilft dir <strong>beim Unternehmenswachstumg</strong>.</>}
            headingBgStart={5}
            headingBgWidth={50}
          />
        </div>
      </div>
      <div className="row content-row" id="google-analytics-setup">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/graphics/grafik-google-analytics.png" 
            alt="Google Analytics Setup und Einstellungen" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Google Analytics & Google Tag Manager Setup"
            text={<>Google Analytics ist als <strong>kostenloser</strong> Dienst von Google eine
              professionelle <strong>Web-Analytics Lösung</strong> für Unternehmen.
              Ein richtiges Setup entfaltet große Potenziale,
              indem Aktivitäten und <strong>Erfolge der eigenen Website messbar</strong> gemacht werden.<br/><br/>
              Du willst noch weiteres Tracking auf deiner Website einbauen? Dann ist Google Tag Manager die kostenlose Lösung, mit der du Tracking-Scripte an einem Ort einfach ohne <strong>Programmierungsaufwand</strong> verwaltest.</>}
            headingBgStart={-5}
            headingBgWidth={60}
          />
        </div>
      </div>
    </Section>
    
    <ContactFormSection 
      heading="Du hast noch unbeantwortete Fragen?"
      text="Schicke uns eine Nachricht mit deinem Anliegen."
    />
    
  </Layout>
  </>
)
export default WebtrackingPage
